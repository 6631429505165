import React from 'react'
import { Link } from 'gatsby'

// import { formatDate, formatTimeDuration } from '../../../_utils'
import GoogleMapLink from './../../atoms/googleMapLink'
import { EventDateRange } from '../../atoms/EventDateRange'

const CoursesListBlock = ({ node }) => {
  return (
    <div className="course-event" key={`course_event_${node.id}`}>
      <h4 className="event-title">
        <Link to={`/courses/${node.fields.slug.replace('/', '')}`}>
          {node.frontmatter.title}
        </Link>
      </h4>
      <EventDateRange
        startDate={node.frontmatter.start_date}
        endDate={node.frontmatter.end_date}
        startTime={node.frontmatter.start_time}
        endTime={node.frontmatter.end_time}
      />
      <div className="event-location-details">
        <GoogleMapLink
          geojson={node?.frontmatter?.geojson}
          locationName={node?.frontmatter?.location_name}
          locationAddress={node?.frontmatter?.location_address}
        />
      </div>
      <div className="event-excerpt">{node.frontmatter.excerpt}</div>
      <Link
        to={`/courses/${node.fields.slug.replace('/', '')}`}
        className="btn btn-primary"
      >
        More Info
      </Link>
    </div>
  )
}

export default CoursesListBlock
