import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col } from 'reactstrap'
import moment from 'moment'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'
import CoursesListBlock from '../components/molecules/coursesListBlock/coursesListBlock'

const pageMeta = {
  title: 'Courses',
  type: 'courses',
  keywords: `lou, groshek, lou groshek, tango, courses, classes, Argentine tango`,
}

const CoursesPage = ({ location }) => {
  const { allMarkdownRemark: allCourses } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "course" } } }) {
          edges {
            node {
              id
              isFuture
              frontmatter {
                date_constrained
                title
                start_date(formatString: "YYYY-MM-DD")
                end_date(formatString: "YYYY-MM-DD")
                start_time
                end_time
                location_name
                location_address
                geojson {
                  lon
                  lat
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const upcomingCourses = allCourses.edges
    .filter((d) => {
      // Only future events.
      return d.node.isFuture
    })
    // Sort ascending farthest out last
    .sort((a, b) =>
      moment(
        `${a.node.frontmatter.start_date} ${a.node.frontmatter.start_time}`
      ).diff(
        moment(
          `${b.node.frontmatter.start_date} ${b.node.frontmatter.start_time}`
        )
      )
    )

  const nonDateCourses = allCourses.edges.filter((d) => {
    return !d.node.frontmatter.date_constrained
  })

  // console.log('upcomingCourses', upcomingCourses)
  // console.log('nonDateCourses', nonDateCourses)

  const scrollToCourses = () => {
    // const goTo = document.querySelector()
    const element = document.getElementById('course_list_target')
    const position = element.getBoundingClientRect()
    const y = position.top - 100
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    })
  }

  return (
    <Layout location={pageMeta.location} pageType={pageMeta.type}>
      <Row className="heading row">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <h2>{pageMeta.title}</h2>
        </Col>
      </Row>
      <Row className="content">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <h3>Upcoming Courses</h3>
          {upcomingCourses.map((item) => {
            return <CoursesListBlock node={item.node} />
          })}
        </Col>
      </Row>
    </Layout>
  )
}

export default CoursesPage

export const Head = () => <SEO meta={{ ...pageMeta }} />
